<template>
  <ul class="wallet-tabs">
    <router-link
        v-for="(item, index) in items"
        :key="index"
        :to="{name: item.route, params: {id: id}}"
        custom
        v-slot="{ navigate, isActive }"
    >
      <li @click="navigate" :class="[isActive && 'active']">{{ item.title }}</li>
    </router-link>
  </ul>
</template>

<script>
export default {
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>


<style lang="less" scoped>
.wallet-tabs {
  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.09);

  li {
    padding-bottom: 15px;
    color: rgba(255, 255, 255, .5);
    border-bottom: 1px solid transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    bottom: -1px;
    transition: all .3s ease;
    user-select: none;

    span {
      background-color: #2a2a2d;
      border-radius: 8px;
      padding: 0 8px;
      font-size: 13px;
      margin-left: 8px;
    }

    &:hover {
      color: rgba(255, 255, 255, 1);
    }
    &.active {
      color: rgba(255, 255, 255, 1);
      border-color: #FDE46A;
      border-width: 2px;
      padding-bottom: 14px
    }
  }
}
</style>
