<template>
  <form @submit.prevent="save" class="name-editor" :class="[mode]" v-click-outside="back">
    <template v-if="mode === 'view'">
      <span class="name-editor-view" :title="wallet.title">{{ wallet.title }}</span>
      <icon-edit @click.stop="edit" class="name-editor-edit" :class="{'hidden': isBlocked}"/>
    </template>
    <template v-else>
      <input v-model="val" class="name-editor-input" maxlength="33" ref="input" placeholder="Enter new name"/>
      <button type="submit">
        <icon-done class="name-editor-save"/>
      </button>
    </template>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import ClickOutside from 'vue-click-outside'

import IconDone from '@/assets/images/name-editor/done.svg?inline'
import IconEdit from '@/assets/images/name-editor/edit.svg?inline'

/**
 * @todo: добавить поддержку enter и escape
 */
export default {
  name: 'NameEditor',
  components: {
    IconDone,
    IconEdit
  },
  directives: {
    ClickOutside
  },
  props: {
    id: [String, Number],
    field: {
      type: String,
      default: 'title'
    }
  },
  data () {
    return {
      mode: 'view',
      val: ''
    }
  },
  computed: {
    ...mapGetters(['getBalanceById', 'permissions']),
    wallet () {
      return this.getBalanceById(this.id)
    },
    isBlocked () {
      return this.wallet.status === 'BLOCKED' || !this.permissions.canWalletEdit
    }
  },
  created() {
    this.val = this.wallet[this.field]
  },
  sockets: {
    'updateWallet' ({ status }) {
      if (status === 'BLOCKED' && this.mode === 'edit') {
        this.back()
      }
    },
  },
  methods: {
    /**
     * Edit mode
     */
    edit () {
      this.mode = 'edit'
      this.$nextTick(() => {
        this.$refs['input'].focus()
      })
    },

    back () {
      this.val = this.wallet[this.field]
      this.mode = 'view'
    },

    /**
     * Save data
     * @return {Promise<void>}
     */
    async save () {
      await this.$sdk.wallet.update(this.id, {
        description: this.val
      }).finally(() => {
        this.mode = 'view'
      })
    }
  }
}
</script>

<style lang="less">
.name-editor {
  display: grid;
  grid-template-columns: 1fr 16px;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  line-height: 13px;
  padding: 3px 3px;
  margin-left: -3px;
  height: 24px;
  top: 23px;
  width: fit-content;

  &-edit,
  &-save {
    width: 16px;
    height: 16px;
    fill: #fff;

    &:hover {
      cursor: pointer;
      fill: #FDE46AFF
    }

    &.hidden {
      display: none;
    }
  }

  &-view {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    width: 100%;
  }

  &-input {
    background-color: transparent;
    border: none;
    color: #FFF;
    font-weight: 500;
    caret-color: #FDE46AFF;
    width: calc(100% - 16px);
    min-width: 150px;
    max-width: 250px;
  }

  &.edit {
    background-color: rgba(255, 255, 255, .05);
    border: 1px solid rgba(255, 255, 255, .1);
    border-radius: 2px;
  }
}
</style>
