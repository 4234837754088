<template>
  <div class="modal small">
    <div class="modal-content">

      <div @click="$emit('close')" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>

      <div class="modal-content-section">
        <h3 class="modal-content-title">Top up wallet</h3>
        <h4 class="modal-content-subtitle">Select a wallet to transfer to <span class="yellow-colored">{{ wallet.title }}</span></h4>

        <div class="modal-content-section">
          <div class="modal-content-inner">

            <div class="grid">
              <div class="col-6">

                <form-group label="Source wallet" :error="errors.wallet_id">
                  <multiselect
                      v-model="form.from"
                      :allow-empty="false"
                      :show-labels="false"
                      :options="balancesFrom"
                      group-values="items"
                      group-label="title"
                      :class="[{'valid' : form.from}, 'top-up-card']"
                      @close="validate(['wallet_id'])"
                      label="title"
                  >
                    <template #option="{ option }">
                      <div v-if="option.$isLabel" content="option__currency-label">
                        <img class="option__currency-label-img" :src="require(`@/assets/images/currency_flags/${option.$groupLabel}.svg`)" alt="">
                        <span class="option__currency-label-text">{{ option.$groupLabel }}</span>
                      </div>
                      <div v-else class="option__currency">
                        <icon v-if="option.isCardWallet" name="p_card" class="wallet-card-icon" />
                        <span class="option__currency-left notranslate">
                          {{ option.title }}
                        </span>
                        <span class="option__currency-right">
                          Balance: <balance-label :amount="option.amount" :currency="option.currency.id" />
                        </span>
                      </div>
                    </template>
                    <template #singleLabel="{ option }">
                      <div class="option__currency">
                        <span class="option__currency-left">
                          {{ option.title }}
                        </span>
                      </div>
                    </template>
                  </multiselect>
                  <div class="form-wallet-balance" v-if="form.from">
                    Balance: <balance-label :amount="form.from.amount" :currency="form.from.currency.id" />
                  </div>
                </form-group>

              </div>

              <div class="col-6">

                <form-group label="Amount" :error="errors.amount">
                  <amount-input
                      v-model="form.amount"
                      @change.native="validate(['amount'])"
                      :class="{'valid': form.amount}"
                      name="amount"
                  />
                  <span class="all" v-if="isAllButtonEnabled" @click="setMaxAmount">All</span>
                </form-group>

              </div>
            </div>

          </div>
          <div class="modal-content-bottom-panel">
            <div class="pull-left" v-if="tax && tax.fee">
              <div class="panel-additional-info for-create-form">
                <div class="panel-block">
                  <span class="panel-block-label">Fee</span>
                  <span class="panel-block-value">
                    {{ tax.fee | numeral('0,0.00') }} {{ tax.currency_id }}
                  </span>
                </div>
              </div>
            </div>
            <button class="default-button" @click="submit" :disabled="!transferEnabled">Transfer</button>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import FormGroup from "../../../../libs/forms/FormGroup";
import Multiselect from "vue-multiselect";
import {mapGetters} from "vuex";
import AmountInput from "../../../modals/AmountInput";
import BalanceLabel from "@/libs/BalanceLabel";
import Icon from "@/libs/Icon";
import groupBy from 'lodash/groupBy'
import {isEqualInt} from "../../../../libs/functions";

export default {
  name: 'TopUpCard',
  components: {
    AmountInput,
    FormGroup,
    Multiselect,
    BalanceLabel,
    Icon
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      form: {
        /**
         * @property {object} from
         * @property {array} from.transferSourceWalletId
         */
        from: null,
        amount: null
      },
      errors: {},

      submitInProcess: false
    }
  },
  computed: {
    ...mapGetters({
      activeBalances: 'getActiveBalances',
      balances: 'getAllBalances'
    }),

    /**
     * Wallet that we top up
     * @returns {*}
     */
    wallet () {
      return this.activeBalances.find(i => isEqualInt(i.id, this.id))
    },

    balancesFiltered () {
      let list = Array.isArray(this.wallet.transferSourceWalletId)
          ? this.wallet.transferSourceWalletId
          : [];

      return this.activeBalances.filter(i => list.includes(i.id))
    },

    balancesFrom () {
      const data = []
      const items = groupBy(this.balancesFiltered, 'currency.id')

      for (const key in items) {
        data.push({ title: key, items: items[key] })
      }

      return data
    },

    formData () {
      return {
        wallet_id: this.form.from && this.form.from.id,
        target_wallet_id: this.wallet.id,
        amount: this.form.amount,
        reference: this.getAutoReference
      }
    },

    isAllButtonEnabled () {
      return this.form.from && this.wallet
    },

    /**
     * @todo Fix for @loal
     * @return {string|undefined}
     */
    getAutoReference () {
      if (this.wallet.isCardWallet) {
        return 'Card account top up'
      }

      return undefined
    },

    transferEnabled () {
      return this.form.from && this.wallet && this.form.amount && !this.submitInProcess
    }
  },
  asyncComputed: {
    tax: {
      get () {
        return this.$sdk.payment.getPaymentFee({
          wallet_id: this.form.from && this.form.from.id,
          target_wallet_id: this.wallet.id,
          amount: this.form.amount,
        }).then(response => response.data)
      },
      shouldUpdate () {
        return this.form.from
            && this.form.amount
            && this.wallet
      },
      default: {
        currency: null,
        fee: null,
        total: null
      }
    },
  },
  methods: {
    close () {
      this.$emit('close')
    },

    validate (attributes = []) {
      this.$sdk.payment.create(this.formData, 1).then(response => {
        const data = response.data

        if (Array.isArray(data) && data.length > 0) {
          this.errors = []
        } else {
          for (const attribute of attributes) {
            if (data[attribute]) {
              this.$set(this.errors, attribute, data[attribute])
            } else {
              this.$delete(this.errors, attribute)
            }
          }
        }
      })
    },
    submit () {
      this.submitInProcess = true

      return this.$sdk.payment.create(this.formData, 0).then(response => {
        if (response.data.length === 0) {
          this.$notice.success('Payment created successfully')
          this.close()
        } else {
          this.errors = response.data
        }

        return response
      }).finally(() => {
        this.submitInProcess = false
      })
    },
    async setMaxAmount () {
      this.form.amount = await this.$sdk.payment.getMaxAmount({
        wallet_id: this.form.from ? this.form.from.id : null,
        target_wallet_id: this.wallet ? this.wallet.id : null
      }).then(response => response.data.maxAmount)

      this.validate('amount')
    },
  }
}
</script>

<style lang="less" >
.multiselect.top-up-card {

  .multiselect__content-wrapper {
    border: 1px solid #2D2D30;
  }
  .multiselect__option {
    position: relative;
    padding-left: 52px;

    .wallet-card-icon {
      position: absolute;
      fill: #FFF;
      opacity: .2;

      top: 8px;
      left: 21px;
    }

    &--group {
      padding-left: 20px;
    }

    &--highlight,
    &:hover {
      .wallet-card-icon {
        fill: #19191C;
        opacity: 1;
      }
      .option__currency-right {
        .balance-label.yellow-colored {
          color: #19191C;
        }
      }
    }

    &--disabled {
      padding-left: 20px;
    }
  }
  .option__currency {
    &-left {
      font-size: 15px;
      font-weight: 500;
    }
    &-right {
      font-size: 13px;
    }
  }
}

</style>

<style lang="less" scoped>
.modal-content-subtitle {
  overflow: hidden;
  white-space: break-spaces;
  text-overflow: ellipsis;
}
</style>
