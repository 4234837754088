<template>
  <div v-cloak class="modal modal-wrapper small opened" v-body-scroll-lock="true">
    <div class="container">
      <div @click="close" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>
      <div class="modal-content">
        <div class="modal-content-section">
          <h3 class="modal-content-title">
            Wallet details
          </h3>
          <div class="slider-wrapper" v-if="wallet.details.length > 1">
            <div class="wallet-details-list-prev">
              <icon name="ic_down"/>
            </div>
            <div class="wallet-details-list" >
              <swiper class="swiper" :options="swiperOptions">
                <swiper-slide v-for="(item, index) in wallet.details" :key="index">
                  <div
                      class="wallet-details-list-item tns-item"
                      @click="activeDetailsId = item.id"
                      :class="{active: activeDetailsId === item.id}"
                      :key="index"
                  >
                    {{ item.label }}
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="wallet-details-list-next">
              <icon name="ic_down"/>
            </div>
          </div>

          <div class="modal-content-inner">
            <div class="grid">

              <div class="col-4" v-if="activeDetails.iban">
                <p class="modal-inner-title">
                  {{ labelForIban  }}
                </p>
                <p class="modal-inner-copy notranslate">
                  {{ activeDetails.iban }}
                </p>
              </div>

              <div class="col-4" v-if="wallet.accountId">
                <p class="modal-inner-title">
                  Account ID
                </p>
                <p class="modal-inner-copy">
                  {{ wallet.accountId }}
                </p>
              </div>

              <div class="col-4" v-if="activeDetails.bic_swift">
                <p class="modal-inner-title">
                  {{ labelForBic }}
                </p>
                <p class="modal-inner-copy notranslate">
                  {{ activeDetails.bic_swift }}
                </p>
              </div>

              <div class="grid">
                <div class="col-4">
                  <p class="modal-inner-title">
                    Currency
                  </p>
                  <p class="modal-inner-copy notranslate">
                    {{ wallet.currency.id }}
                  </p>
                </div>

                <div class="col-4">
                  <p class="modal-inner-title">
                    Account name
                  </p>
                  <p class="modal-inner-copy notranslate">
                    {{ activeDetails.bank_account_holder_name }}
                  </p>
                </div>

                <div class="col-4">
                  <p class="modal-inner-title">
                    Bank name
                  </p>
                  <p class="modal-inner-copy notranslate">
                    {{ activeDetails.bank_name }}
                  </p>
                </div>
              </div>

              <div class="col-12" v-if="activeDetails.bank_address">
                <p class="modal-inner-title">
                  Bank address
                </p>
                <p class="modal-inner-copy notranslate">
                  {{ activeDetails.bank_address }}
                </p>
              </div>

            </div>

            <template v-if="wallet.accountId">
              <div class="wallet-details-notification">
                <icon name="ic_info_2" class="icon" /> To receive funds please make sure your Account ID is specified in the payment reference
              </div>
            </template>
          </div>

          <div class="modal-content-bottom-panel">
            <div class="pull-left">
              <button @click="copy" class="next-form-button">
                <icon name="ic_copy_white" />
                <span>Copy</span>
              </button>
            </div>
            <div class="pull-right">
              <button @click="close" class="next-form-button">
                <icon name="icp_close" />
                <span>Close</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import Icon from "../../../../libs/Icon";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import 'swiper/css/swiper.css'

export default {
  name: 'WalletDetailsModal',
  components: {
    Icon,
    Swiper,
    SwiperSlide
  },
  props: {
    id: {
      type: Number
    }
  },
  data () {
    return {
      activeDetailsId: null,
      swiperOptions: {
        slidesPerView: 1,
        navigation: {
          nextEl: '.wallet-details-list-next',
          prevEl: '.wallet-details-list-prev',
        },
        breakpoints: {
          1150: { slidesPerView: 4 },
          768:  { slidesPerView: 2 },
          481:  { slidesPerView: 2 },
        }
      },
    }
  },
  computed: {
    ...mapGetters(['getBalanceById']),
    ...mapState({
      balances: state => state.balance.items,
      settings: state => state.site.settings
    }),
    wallet () {
      return this.getBalanceById(this.id)
    },
    activeDetails () {
      return this.wallet.details.find(item => {
        return item.id === this.activeDetailsId
      })
    },

    /**
     * Check is it local payment in GBP and bank country United Kingdom
     * @jira AL
     * @return {boolean}
     */
    isLocalPayment () {
      return this.activeDetails
          && this.wallet.currency
          && this.wallet.currency.id === 'GBP'
          && this.isSortCode
    },

    isSortCode () {
      return /^[0-9]+$/.test(this.activeDetails.bic_swift) === true
    },

    labelForIban () {
      if (this.isLocalPayment) {
        return 'Account number'
      } else {
        return 'IBAN'
      }
    },

    labelForBic () {
      if (this.isLocalPayment) {
        return 'Sort Code'
      } else {
        return 'BIC/SWIFT'
      }
    },
  },
  created() {
    if (!this.activeDetailsId) {
      this.activeDetailsId = this.wallet.details.find(item => {
        return item.is_main === true
      }).id
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    copy () {
      let str = "My " + this.settings.brand_name + " " + this.wallet.currency.id +  " account details are:"
      let arr = [
        {
          label: 'Bank Account Holder Name',
          value: this.activeDetails.bank_account_holder_name,
          required: true
        },
        {
          label: this.labelForIban,
          value: this.activeDetails.iban,
          required: true
        },
        {
          label: this.labelForBic,
          value: this.activeDetails.bic_swift,
          required: true
        },
        {
          label: 'Account ID',
          value: this.wallet.accountId,
          required: false
        },
        {
          label: 'Bank Name',
          value: this.activeDetails.bank_name,
          required: true
        },
        {
          label: 'Bank Address',
          value: this.activeDetails.bank_address,
          required: true
        }
      ]

      arr.forEach((item) => {
        if (item.required || item.value) {
          str += "\n" + item.label + ': ' + (item.value || '')
        }
      })

      this.$copyText(str).then(() => {
        this.$notice.info('Copied')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.slider-wrapper {
  display: flex;
  margin-bottom: 40px;
}

.wallet-details-list {
  user-select: none;
  width: 100%;

  .swiper-container {
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);
  }

  &-item {
    padding: 13px 25px 11px 25px;;
    cursor: pointer;
    text-align: center;
    font-size: 14px;

    &.active {
      color: #FDE46A;
      border-bottom: 1px solid #FDE46A;
    }
  }

  &-prev,
  &-next {
    cursor: pointer;

    svg {
      fill: #666;
      transform: rotate(90deg);
    }

    &:hover {
      svg {
        fill: #FFF;
      }
    }

    &[aria-disabled=true] {
      display: none;
    }
  }

  &-next {
    svg {
      transform: rotate(-90deg);
    }
  }
}

.wallet-details-notification {
  font-size: 14px;
  color: rgba(255, 255, 255, .6);
  border-radius: 2px;
  background-color: #262629;
  padding: 16px 36px 16px 20px;
  width: 100%;

  .icon {
    vertical-align: middle;
    margin-right: 6px;
    fill: #FDE46A;
  }
}

.account-id-icon {
  vertical-align: middle;
  width: 14px;
  position: relative;
  top: -2px;
  fill: #fff;
  margin-left: 3px;

  &:hover {
    //fill: #FFF;
    cursor: pointer;
  }
}
</style>
