<template>
  <div id="actionsButtonsContainer">
    <div id="actionsButtonsWrapper" ref="actionsButtonsWrapper" :class="[btnsRight && 'actions-buttons-right']">
      <button v-for="(button, index) in visibleButtons"
              @click="button.action"
              class="btn"
              :class="[{highlighted: index === 0}, button.class]"
              :key="index"
              :disabled="button.disabled"
      >
        {{ button.label }}
      </button>

      <div class="btns-dropdown-wrapper" v-if="hiddenButtons.length > 0" v-click-outside="close">
        <button
            class="btn more-actions"
            :class="{active: dropdownActive}"
            @click.stop="toggle"
            ref="moreActionsButton"
        />
        <slide-y-up-transition :duration="150">
          <ul v-show="dropdownActive" class="btns-dropdown" :class="dropdownPosition">
            <li v-for="(button, index) in hiddenButtons"
                @click="button.action"
                class="dropdown-btn"
                :class="[{disabled: button.disabled}, button.class]"
                :key="index"
            >
              {{ button.label }}
            </li>
          </ul>
        </slide-y-up-transition>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
import ClickOutside from 'vue-click-outside'
import { SlideYUpTransition } from 'vue2-transitions'

export default {
  name: 'ActionsButtons',
  components: {
    SlideYUpTransition,
  },
  directives: {
    ClickOutside
  },
  props: {
    /**
     * Items in format
     *
     * {
     *   label: String,
     *   class: String,
     *   isVisible: Boolean,
     *   show: Boolean,
     *   action: Function
     * }
     *
     * @var {Array} items
     */
    btns: {
      type: Array,
      default: () => []
    },

    /**
     * Buttons position in container
     */
    btnsRight: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      observer: null,
      observableEl: null,
      showMore: false,
      dropdownActive: false,
      buttons: [],
      dropdownPosition: 'aligned-left',
      buttonsWrapper: null
    }
  },
  created () {
    this.buttons = cloneDeep(this.btns)
  },
  mounted () {
    this.buttonsWrapper = this.$refs.actionsButtonsWrapper

    this.observableEl = document.querySelector("#actionsButtonsContainer")
    this.observer = new ResizeObserver((entries) => {
      this.recalculate(entries[0].contentRect.width)
    })

    this.observer.observe(this.observableEl)
  },
  beforeDestroy() {
    this.observer.unobserve(this.observableEl);
  },
  computed: {
    visibleButtons() {
      return this.buttons.filter(button => button.isVisible);
    },
    hiddenButtons() {
      return this.buttons.filter(button => !button.isVisible);
    },
  },
  methods: {
    recalculate: debounce(async function (containerWidth) {
      this.hideMoreButton()

      for (const button of this.buttons) {
        button.isVisible = false
      }

      for (const button of this.buttons) {
        button.isVisible = true
        await this.$nextTick()

        let buttonsWidth = this.buttonsWrapper.scrollWidth

        if (buttonsWidth > containerWidth) {
          button.isVisible = false;
          this.showMoreButton()
          await this.$nextTick()
          this.calculateDropdownPosition()
          break
        }
      }
      this.calculateDropdownPosition()
    }, 100),
    showMoreButton() {
      this.showMore = true;
    },
    hideMoreButton() {
      this.showMore = false;
    },
    toggle () {
      this.dropdownActive = !this.dropdownActive
    },
    close () {
      this.dropdownActive = false
    },
    calculateDropdownPosition () {
      const pageContentEl = document.querySelector(".page-content")
      if (this.$refs.moreActionsButton) {
        this.dropdownPosition = (pageContentEl.getBoundingClientRect().right - this.observableEl.getBoundingClientRect().right < this.$refs.moreActionsButton.scrollWidth * 2)
            ? 'aligned-right'
            : 'aligned-left'
      }
    }
  }
}
</script>

<style lang="less">
#actionsButtonsContainer {
  display: flex;
  align-items: center;
  width: 100%;
}
#actionsButtonsWrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.actions-buttons-right {
  margin-left: auto;
}
</style>

