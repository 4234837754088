<template>
  <div class="wh_panel">

    <div class="wh_panel_left">

      <router-link :to="{name: 'Wallets'}" class="wh_back">
        <icon name="arrow_back" />
      </router-link>

      <div class="wh_balance">
        <div class="wh_balance_first">{{ wallet.amount | numeral('0,0.00') }} {{ wallet.currency.id }}</div>
        <div class="wh_balance_second">
          <name-editor :id="id" />
        </div>
      </div>

    </div>

    <div class="wh_panel_right">
      <actions-buttons :btns="filteredItems" btnsRight />
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Icon from "@/libs/Icon";
import NameEditor from "./NameEditor";
import PaymentCreateModal from "@/components/modals/PaymentCreateModal.vue";
import ConversionForm from "@/components/modals/ConversionForm";
import WalletDetailsModal from "@/components/wallet/payments/modals/WalletDetailsModal.vue";
import TopUp from "@/components/modals/TopUp";
import TopUpCard from "@/components/wallet/cards/modals/TopUpCard.vue";
import CreateCard from "@/components/wallet/cards/modals/CreateCard.vue";
import ActionsButtons from "@/components/wallet/parts/ActionsButtons.vue";
import {FEATURES} from "@/store/modules/features";

export default {
  name: 'WalletHeaderPanel',
  components: {
    ActionsButtons,
    NameEditor,
    Icon
  },
  props: ['id'],
  computed: {
    ...mapGetters([
        'getBalanceById',
        'permissions',
        'canSupport',
        'hasTopUpFeature',
    ]),

    items () {
      return [
        {
          label: "Order a card",
          class: "order-card",
          isVisible: false,
          disabled: false,
          show: this.canOrderCard,
          action: () => {
            return this.makeCardOrder(this.wallet.id)
          }
        },
        {
          label: "Make payment",
          class: "make-payment",
          isVisible: false,
          disabled: false,
          show: this.canPaymentCreate,
          action: this.makeNewPayment
        },
        {
          label: "Top up",
          class: "topup-card",
          isVisible: false,
          disabled: false,
          show: this.canTopUp,
          action: this.makeTopUp
        },
        {
          label: "Top up wallet",
          class: "topup-wallet",
          isVisible: false,
          disabled: false,
          show: this.canTopUpCardWallet,
          action: () => {
            return this.makeTopUpCardWallet(this.wallet.id)
          }
        },
        {
          label: "Currency exchange",
          class: "currency-exchange",
          isVisible: false,
          disabled: false,
          show: this.canMakeConversions,
          action: this.makeConversion
        },
        {
          label: "Bank details",
          class: "bank-details",
          isVisible: false,
          disabled: false,
          show: this.canShowBankDetails,
          action: this.showWalletDetailsModal
        }
      ]
    },

    /**
     * Check is it e-wallet
     *
     * @return {boolean}
     */
    canTopUp () {
      return this.hasTopUpFeature
          && (this.wallet
            ? this.permissions.canWalletTopUp && this.wallet.permissions.canTopUp
            : this.permissions.canWalletTopUp)
    },

    /**
     * Check if user have many currencies
     * for conversions
     */
    canMakeConversions () {
      return this.wallet
          ? this.permissions.canCurrencyExchangeCreate && this.wallet.permissions.canCurrencyExchange
          : this.permissions.canCurrencyExchangeCreate
    },

    canPaymentCreate () {
      return this.wallet
          ? (this.permissions.canPaymentCreate || this.permissions.canPaymentDraftCreate) && this.wallet.permissions.canPay
          : this.permissions.canPaymentCreate || this.permissions.canPaymentDraftCreate
    },

    canShowBankDetails () {
      return !this.wallet.isCardWallet
    },

    canOrderCard () {
      return this.wallet
          ? this.permissions.canCardCreate && this.canSupport(FEATURES.CARD) && this.wallet.permissions.canCardCreate
          : this.permissions.canCardCreate && this.canSupport(FEATURES.CARD)
    },

    canTopUpCardWallet () {
      return this.wallet
          ? this.permissions.canWalletTransfer && this.wallet.permissions.canTopUpFromWallet
          : this.permissions.canWalletTransfer
    },

    wallet () {
      return this.getBalanceById(this.id)
    },

    /**
     * @return {({show, action: *, text: string}|{show, action: *, text: string}|{show: *, action: *, text: string}|{show: *, action: *, text: string}|{show: *, action: *, text: string})[]}
     */
    filteredItems () {
      return this.items.filter(i => i.show === true)
    },
  },
  methods: {
    makeNewPayment () {
      this.$modal.show(PaymentCreateModal, {
        walletId: this.wallet.id
      })
    },
    makeConversion () {
      this.$modal.show(ConversionForm,  {
        walletId: this.wallet.id
      })
    },
    showWalletDetailsModal () {
      this.$modal.show(WalletDetailsModal,  {
        id: this.wallet.id
      })
    },
    makeTopUp () {
      this.$modal.show(TopUp)
    },
    makeCardOrder (id) {
      this.$modal.show(CreateCard, id ? {
        id: id
      } : undefined)
    },
    makeTopUpCardWallet (id) {
      return this.$modal.show(TopUpCard, {
        id: id
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wh_panel {
  display: flex;
  padding: 30px;
  grid-gap: 20px;
  justify-content: space-between;

  &_left {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 16px;
  }

  &_right {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.wh_back {
  svg {
    fill: #FFF;
  }
}

.wh_balance {
  display: grid;
  grid-template-areas:
      "icon balance_1"
      "icon balance_2"
  ;

  grid-template-columns: min-content 1fr;
  grid-row-gap: 0;
  position: relative;

  font-size: 20px;
  color: #FFFFFF;

  &_first {
    font-size: 20px;
    font-weight: 600;

    grid-area: balance_1;
    white-space: nowrap;
  }

  &_second {
    color: #FDE46A;

    font-size: 12px;
    font-weight: 500;

    letter-spacing: 0;
    grid-area: balance_2;
    width: 100%;
  }

  &_icon {
    grid-area: icon;

    &.wh_balance_icon_flag {
      position: relative;
      top: 3px;
    }

    &.wh_balance_icon_marker {
      width: 16px;
      height: 16px;
      display: inline-block;
      border-radius: 50%;

      background-color: #FFF;
      margin-top: 4px;
    }
  }
}
</style>
