<template>
  <div class="main-content" ref="content">
    <wallet-header-panel :id="id" />

    <div class="wallet-tabs-wrap" v-if="wallet.isCardWallet">
      <wallet-tabs :id="id" :items="filteredTabs" />
    </div>

    <div class="delimiter" v-if="!wallet.isCardWallet" />

    <router-view :key="$route.fullPath"/>
  </div>
</template>

<script>
import WalletHeaderPanel from "./parts/WalletHeaderPanel";
import WalletTabs from './parts/WalletTabs'

import { mapGetters } from "vuex";
import { FEATURES } from "@/store/modules/features";

export default {
  components: {
    WalletTabs,
    WalletHeaderPanel,
  },
  props: ['id'],
  computed: {
    ...mapGetters([
      'getBalanceById',
      'canSupport'
    ]),

    /**
     * Active wallet
     *
     * @return {Object}
     */
    wallet () {
      return this.getBalanceById(this.id)
    },
    isCardsAvailable () {
      return this.wallet.isCardWallet && this.canSupport(FEATURES.CARD)
    },
    filteredTabs () {
      return [
        {
          title: 'Cards',
          route: 'WalletCards',
          isAvailable: () => this.isCardsAvailable
        },
        {
          title: 'Transactions',
          route: 'WalletPayments',
          isAvailable: () => true,
        }
      ].filter((item) => item.isAvailable())
    }
  },
}
</script>


<style lang="less" scoped>
.delimiter {
  background-color: rgba(255, 255, 255, 0.09);
  width: 100%;
  height: 1px;
}

.wallet-tabs-wrap {
  padding: 0 30px;
  position: relative;
}

.status-bar {
  position: absolute;
  right: 30px;
  top: -13px;
}
</style>
